import React from "react";
import './Footer.css'

const Footer: React.FC = () => {
    return(
        <>
            <div id="footer" className="footer-wrap">
                <div className="footer-gift-wrap" onClick={() => window.location.href='https://t.me/Ulaprize_bot/Webappula'} style={{backgroundImage: "url('img/industrias/red-gift-box-with-ribbon-bow-vector-illustration 1.png')"}}>
                    <span className="footer-gift-title">Хотите 100 000 <br></br>на закупку товаров? <br></br>Участвуйте!</span>
                    <span className="footer-gift-description">Пройдите короткий опрос и получите шанс выиграть средства для выгодных закупок.</span>
                    <div className="footer-gift-btn" style={{cursor: 'pointer'}}>
                        <span  >Пройти опрос</span>
                        <img src="img\industrias\Arrow_right_light.svg"/>
                    </div>

                </div>

                <div className="footer-content-devider">
                    <div className="footer-info-content-wrap-1">
                        <div className="footer-contacts-wrap">
                            <span className="footer-main-text">8 (800) 777-91-67</span>
                            <span className="footer-main-text">manager@ula.global</span>
                            <span className="footer-main-text">г. Москва, Ленинский<br></br>проспект 111, к1</span>

                        </div>

                        <div className="footer-parts-wrap">
                            <span className="footer-main-text"><a href="#about-us" style={{color: 'black'}}>О нас</a></span>
                            <span className="footer-main-text"><a href="#transportation" style={{color: 'black'}}>Услуги</a></span>
                            <span className="footer-main-text"><a href="#calculator" style={{color: 'black'}}>Калькулятор</a></span>
                            {/* <span className="footer-main-text">Кейсы</span> */}
                        </div>

                        <div className="footer-parts-wrap">
                            <span className="footer-main-text"><a href="#blog" style={{color: 'black'}}>Новости</a></span>
                            <span className="footer-main-text"><a href="#footer" style={{color: 'black'}}>Контакты</a></span>
                            <span className="footer-main-text"><a href="#about-us" style={{color: 'black'}}>Офисы и склады</a></span>
                        </div>

                        <div className="footer-social-links-wrap">
                            <span className="footer-main-text">Telegram канал</span>
                            <span className="footer-main-text">Telegram чат</span>
                            <span className="footer-main-text">Вконтакте</span>
                            <span className="footer-main-text">VC.ru</span>

                        </div>
                    </div>
                    <div className="footer-info-content-wrap-2">
                        <span>ООО «ЮЛА ГЛОБАЛ»</span>
                        <span>Реквизиты</span>
                        <span><a href="/Соглашение об обработке персональных данных.pdf" download="Соглашение об обработке персональных данных.pdf" style={{color: '#4B535B'}}>Политика конфиденциальности</a></span>
                    </div>
                    
                </div>

            </div>
        </>
    )
}

export default Footer;